<template>
  <v-container fluid class="pa-0 ma-0">
    <v-layout align-center justify-center v-if="loading">
      <a-spin tip="Loading..."> </a-spin>
    </v-layout>
    <template v-else>
      <!-- <v-btn @click="fetchImage()">get image</v-btn> -->
      <v-toolbar color="#f0f0f0">
        <v-toolbar-title>
          <strong>{{ sekkeiData.docNo }}</strong>
        </v-toolbar-title>
        <v-btn
          small
          dense
          dark
          rounded
          color="#1565c0"
          @click="CloseTab()"
          class="ml-5"
        >
          <v-icon>mdi-arrow-left</v-icon>Back
        </v-btn>
        <v-btn
          dense
          small
          rounded
          @click="print()"
          color="success"
          class="ml-1"
          width="105px"
          :disabled="sekkeiData.textContent == 'Attachment Only'"
        >
          <v-icon color="#FAFAFA">mdi-printer</v-icon>Print
        </v-btn>
        <v-btn
          @click="addFavorite()"
          class="ml-1"
          small
          rounded
          color="success"
        >
          <v-icon color="yellow">
            {{ isDocFavorite ? " mdi-star" : " mdi-star-outline" }}</v-icon
          >Favorite</v-btn
        >
        <v-btn
          small
          dense
          rounded
          @click="feedback()"
          color="success"
          class="ml-1"
          width="135px"
        >
          <v-icon color="#FAFAFA">mdi-comment-quote</v-icon>Feedback
        </v-btn>
        <v-spacer />

        <!-- <div v-if="fileLoading">
          <v-progress-circular
            color="green"
            indeterminate
          ></v-progress-circular>
          <span>The File is being Process......</span>
        </div>
        <div style="float:right;" v-if="fileNamePdf && fileLoading == false">
          <span>If the file didnt load </span
          ><span style="color:green;" role="button" @click="getFile()"
            >click here
          </span>
          <v-progress-circular
            v-if="loadAgainLoading"
            indeterminate
            :size="15"
          ></v-progress-circular>
        </div> -->

        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }" class="mr-10">
            <v-icon
              color="#0792b0"
              large
              v-on="on"
              @click="
                historyDialog = true;
                sortHistory();
              "
            >
              mdi-timeline-text
            </v-icon>
          </template>
          <span>View History</span>
        </v-tooltip> -->
      </v-toolbar>
      <!-- <v-alert
        dark
        type="warning"
        dismissible
        v-if="this.excelAlert == true"
        >
        <strong>Note: </strong> If the Excel file doesn't download.
        <v-btn color="info" @click="warningDialog = true">Click Here</v-btn>
      </v-alert> -->

      <v-card tile flat id="print">
        <v-alert
          dismissible
          prominent
          type="error"
          color="#fb8c00"
          dense
          border="left"
          class="mx-1 mt-1"
          elevation="2"
          v-if="this.excelAlert == true"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong>Note: </strong> If the Excel file doesn't download.
              <span
                style="text-decoration: underline; font-weight: 700; cursor: pointer;"
                @click="warningDialog = true"
                >Click here.</span
              >
            </v-col>
            <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
        </v-col> -->
          </v-row>
        </v-alert>
        <v-alert
          dismissible
          prominent
          type="error"
          color="#fb8c00"
          dense
          border="left"
          class="mx-1 mt-1"
          elevation="2"
          v-if="this.pdfAlert == true"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong>Note: </strong> If the Pdf file failed to download.
              <span
                style="text-decoration: underline; font-weight: 700; cursor: pointer;"
                @click="getFile"
                >Click here.
              </span>
              <v-progress-circular
                v-if="loadAgainLoading"
                indeterminate
                :size="15"
              ></v-progress-circular>
            </v-col>
            <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
          </v-col> -->
          </v-row>
        </v-alert>
        <v-alert
          dismissible
          prominent
          type="error"
          color="#fb8c00"
          dense
          border="left"
          class="mx-1 mt-1"
          elevation="2"
          v-if="this.folderAlert == true"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong>Note: </strong> If the Folder/zip file failed to download.
              <span
                style="text-decoration: underline; font-weight: 700; cursor: pointer;"
                @click="getFile"
                >Click here.
              </span>
              <v-progress-circular
                v-if="loadAgainLoading"
                indeterminate
                :size="15"
              ></v-progress-circular>
            </v-col>
            <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
        </v-col> -->
          </v-row>
        </v-alert>
        <a-row type="flex" justify="start" v-if="hideProperties">
          <a-col>
            <v-btn class="disabled" rounded text>
              <span class="ml-2"> メール アドレス: </span>
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  class="ml-4"
                  v-on="on"
                  @click="Copy()"
                  v-clipboard:copy="thingToCopy"
                  >{{ sekkeiData.MailAddress }}
                </a>
              </template>
              <span>Copy mail address to clipboard</span>
            </v-tooltip>

            <v-btn class="disabled" rounded text>
              <span class="ml-5">
                仕様マニュアル更新日：
                <span
                  v-if="sekkeiData.updatedDate == null"
                  style="text-transform: capitalize;"
                  >Original Copy</span
                >
                <span v-else>{{ sekkeiData.updatedDate }}</span>
              </span>
            </v-btn>
          </a-col>
          <a-col :span="24"><hr /></a-col>
        </a-row>

        <a-row type="flex" justify="start" v-if="hideProperties">
          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>区分: {{ sekkeiData.sekkei_classification }}</span>
              <!-- <span v-else
                >建物構法:
                {{ sekkeiData.data[sekkeiData.version - 1].framework }}</span
              > -->
            </v-btn>
          </a-col>

          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>項目: {{ sekkeiData.sekkei_projects }}</span>
              <!-- <span v-else
                >資料名:
                {{
                  sekkeiData.data[sekkeiData.version - 1].specifications
                }}</span
              > -->
            </v-btn>
          </a-col>

          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>資料種類: {{ sekkeiData.sekkei_materials }}</span>
              <!-- <span v-else
                >部屋・部位:{{
                  roomPart(sekkeiData.data[sekkeiData.version - 1].roomPart)
                }}</span
              > -->
            </v-btn>
          </a-col>

          <!-- <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span v-if="shiyoData.version == 0">しよ番号: {{shiyoData.shiyoNumber}}</span>
              <span v-else>しよ番号: {{shiyoData.data[shiyoData.version - 1].shiyoNumber}}</span>
            </v-btn>
          </a-col> -->
          <a-col :span="24"><hr /></a-col>
        </a-row>
        <!-- ********************************************************************************** -->
        <!-- <v-row no-gutters v-if="hideProperties">
          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].G"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon
              >&nbsp;グラン・セゾン
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Gsmart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon
              >&nbsp;グラン・スマート
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Menshin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;免震
            </v-btn>
          </v-col>

          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].F"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンF
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Ismart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-smart
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Taishin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;耐震
            </v-btn>
          </v-col>

          <v-col cols="2.4"> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].F2TD">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンF2-TD
            </v-btn> -->
        <!-- <br /> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Icube"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-cube
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].A"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンA
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Ismile"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-smile
            </v-btn> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].YumeNoIe">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;夢の家
            </v-btn> -->
        <!-- </v-col>

          <v-col cols="2.4"> -->
        <!-- <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].Ippan">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;一般
            </v-btn> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].B"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;ブリアール
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon
                v-if="sekkeiData.data[sekkeiData.version - 1].JapaneseStyle"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;和風百年
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Famille"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon
              >&nbsp;ファミーユ（３階建）
            </v-btn>
          </v-col> -->

        <!-- <v-col cols="2.4">
            
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].Saison">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンアシュレ
            </v-btn>
          </v-col> -->

        <!-- <v-col cols="2.4">
         
            <v-icon
              class="py-3"
              color="black"
              v-if="sekkeiData.data[sekkeiData.version - 1].HUGme"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon class="py-3" color="black" v-else
              >mdi-close-circle-outline</v-icon
            >&nbsp;HUGme
            <br />
         
            <v-icon
              color="black"
              v-if="sekkeiData.data[sekkeiData.version - 1].HUGmefam"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon color="black" v-else>mdi-close-circle-outline</v-icon
            >&nbsp;HUGme fam
          
          </v-col>
        </v-row> -->

        <hr />
        <v-layout pt-4 pl-4>
          <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
            件名:
          </p>

          <p
            style="font-size: 20px; padding-top: 0; font-weight: bold;"
            v-html="sekkeiData.title.toUpperCase()"
          ></p>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="hideProperties = !hideProperties">
            <span v-if="!hideProperties"
              >View more details
              <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
            </span>
            <span v-else
              >Hide details <v-icon>mdi-arrow-up-drop-circle-outline</v-icon>
            </span>
          </v-btn>
        </v-layout>
        <hr />

        <v-card
          class="ma-1 pa-1"
          flat
          :height="!hideProperties ? screenSize - 160 : screenSize - 350"
          style="overflow-y: scroll"
        >
          <!-- <v-flex xs12> -->
          <!-- <div v-if="shiyoData.version == 0" class="fr-view" v-html="shiyoData.textContent"></div> -->
          <!-- <div class="fr-view" v-html="shiyoData.data[shiyoData.version - 1].textContent"></div> -->

          <v-flex xs12 v-for="(item, i) in sekkeiData.htmlContent" :key="i">
            <div v-html="item"></div>
          </v-flex>

          <!-- <v-flex>
            <div v-html="sekkeiData.htmlContent"></div>
          </v-flex> -->

          <!-- <div v-else>
              <v-flex v-for="(item,i) in shiyoData.data[shiyoData.version - 1].textContent" :key="i">
                <div v-html="item"></div>
              </v-flex>
            </div> -->
          <v-spacer></v-spacer>
          <!-- </v-flex> -->
        </v-card>

        <!-- <v-card
          class="ma-1 pa-1"
          flat
          :height="!hideProperties ? screenSize - 150 : screenSize - 80"
          style="overflow-y: scroll"
        >
          <editor
            class="px-3"
            :init=tiny_init
            v-model="shiyoData.textContent"
            height="1500"
            id="tinymce_viewing"
          ></editor>
        </v-card> -->
      </v-card>
    </template>

    <v-dialog v-model="historyDialog" max-width="700px" scrollable>
      <v-card>
        <v-toolbar dense flat>
          <span style="font-size: 24px">History Logs </span>
          <v-spacer></v-spacer>
          <v-icon @click="historyDialog = false">mdi-close</v-icon>
        </v-toolbar>

        <a-row type="flex" justify="start">
          <a-col :span="7" style="text-align: center"> Updated Date </a-col>
          <a-col :span="10" style="text-align: center"> Remarks </a-col>
          <a-col :span="7" style="text-align: center"> Updated by </a-col>
        </a-row>
        <template v-for="(item, index) in historyDetails">
          <v-card
            style="cursor: pointer"
            @click="
              viewHistory(index);
              historyDialog = false;
            "
            :key="index"
            flat
          >
            <v-layout
              id="hover"
              @click="searchList = index"
              :class="listColorSearch(index)"
            >
              <v-flex xs12 s6 md7 class="pl-3">
                <!-- {{ index + 1 }}. &nbsp; -->
                {{ index + 1 }}. &nbsp;
                {{ item.updatedDate }}
              </v-flex>
              <v-flex
                xs12
                s6
                md10
                style="text-align: justify; padding-left: 35px"
              >
                {{ item.remarks }}
              </v-flex>
              <v-flex
                xs12
                s6
                md7
                style="text-align: justify; padding-left: 35px"
              >
                {{ item.updatedBy }}
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-card>
    </v-dialog>

    <!-- mike -->
    <v-dialog
      v-model="ViewPdfDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-if="ViewPdfDialog">
        <pdf-viewer
          :closeShiyoDialog="onCloseShiyoDialog"
          :shiyoDetails="sekkeiData"
        />
      </template>
    </v-dialog>

    <template>
      <v-row justify="center">
        <v-dialog v-model="warningDialog">
          <v-toolbar dark color="primary">
            <v-spacer />
            <v-btn icon dark @click="warningDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card
            class="pa-3"
            tile
            :height="$vuetify.breakpoint.height - 200"
            style="overflow-y : auto"
          >
            <v-img
              class="mb-16"
              src="/ShiyoManual_Image/alert2.png"
              width="100%"
              position="center"
            >
            </v-img>

            <!-- <v-img 
              src="/ShiyoManual_Image/alert1.png"
              width="100%"
              position="center"
            >
            </v-img> -->
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
import * as binconv from "binconv";
// import converter from "base64-arraybuffer";
import axios from "axios";
import Swal from "sweetalert2";
import moji from "moji";
// import Editor from "@tinymce/tinymce-vue";
// import tinymce from "tinymce/tinymce";

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
// import theme from '!!raw-loader!tinymce/themes/silver/theme.min.js'

//mike
// import ShiyoPdfViewer from "../components/ShiyoPdfViewer.vue";
import fileView from "./SekkeiGyoumuFileViewing";

export default {
  title() {
    return `@Sekkei Gyoumu ${this.$route.params.id.substring(0, 9)}`;
  },
  components: {
    // 'editor': Editor,
    // "pdf-viewer": ShiyoPdfViewer,
  },

  name: "newPage",
  props: ["search"],
  data() {
    return {
      tiny_init: {
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        menubar: false,
        visual: false,
        skin: false,
        content_css: false,
        content_style:
          [contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
        // content_style: [contentCss, contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
      },
      fileLoading: false,
      loadAgainLoading: false,
      pdfAlert: false,
      fileNamePdf: "",
      loading: false,
      thingToCopy: `sk-manual@ichijo.co.jp`,
      title_spacer: "　",
      sekkeiData: {
        _id: "",
        _rev: "0",
        documentType: "SekkeiGyoumu",
        version: 1,
        data: [
          {
            framework: "",
            productName: "",
            roomPart: "",
            shiyoNumber: "",
            specifications: "",
            textContent: "",
            UpdatedDate: "",
            updatedBy: "",
          },
        ],
      },
      historyDialog: false,
      searchList: "",
      historyDetails: [],
      historyCount: 0,
      hideProperties: true,
      ViewPdfDialog: false,
      warningDialog: false,
      excelAlert: false,
      folderAlert: false,
      folderFileName: "",
      isDocFavorite: false,
      favoriteList: "",
      //mike
      // scale: "auto",
      // src: "",
    };
  },
  created() {
    // alert("This part is still on Development  :)");
    this.getData();
    this.getUserFavorites();

    // let testUrl = window.location.href;
    // let url = "";
    // let search = "";
    // let id = this.$route.params.id;
    // if (this.$route.query.search != "") {
    //   search = this.$route.query.search;
    // }
    // console.log(search);
    // this.loading = true;
    // // url = `${this.api}shiyo/get?id=${this.$route.params.id}&search=${search}`;
    // // url = `${this.api}shiyo/get?id=${this.$route.params.id}&search=${search}`;
    // url = `${this.awsURL}sekkeiGyoumu/getSpecificSekkeiGyoumu/${id}`;
    // axios.get(url).then((res) => {
    //   // console.log('line 370 ',res.data)
    //   // document.title = `${res.data.shiyoNumber}-${res.data.productName}`;
    //   document.title = `${res.data.docNo}`;
    //   this.sekkeiData = res.data;
    //   console.log("line 405 ", res.data);
    //   if (res.data.data[res.data.version - 1].isWithPDFAttachment) {
    //     // alert('DITO KA MAG CODE')
    //     this.ViewPdfDialog = true;
    //   } else if (res.data.data[res.data.version - 1].isWithExcelAttachment) {
    //     console.log(
    //       "line 412 nag else if",
    //       res.data.data[res.data.version - 1].isWithExcelAttachment
    //     );
    //     this.excelAlert =
    //       res.data.data[res.data.version - 1].isWithExcelAttachment;
    //     let url =
    //       "https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files/";
    //     // let newFileName = res.data.data[res.data.version - 1].fileName.split('.')[0]
    //     setTimeout(() => {
    //       window.open(`${url}${res.data.data[res.data.version - 1].fileName}`);
    //       // window.open(`https://www.google.com`, '_blank')
    //     }, 300);
    //   } else {
    //     if (testUrl.match(/index/g)) {
    //       let testSplit = testUrl.split("index");
    //       let getInd = testSplit[1].split("")[1];
    //       this.sekkeiData.version = parseInt(getInd) + 1;
    //     }
    //     let newTextContent = "";
    //     if (this.sekkeiData.version == 0) {
    //       this.sekkeiData.textContent.map((rec) => {
    //         newTextContent += rec;
    //         console.log("newTextContent", newTextContent);
    //       });
    //     } else {
    //       this.sekkeiData.data[this.sekkeiData.version - 1].textContent.map(
    //         (rec) => {
    //           newTextContent += rec;
    //         }
    //       );
    //     }

    //     let tempTextContent = newTextContent.replace(
    //       /href="document/g,
    //       'href="/document'
    //     ); // for rulebook documents si alde po may gawa nyan
    //     this.sekkeiData.textContent = tempTextContent.replace(
    //       /shiyo_document/g,
    //       "/shiyo_document"
    //     );
    //     setTimeout(() => {
    //       tinymce.activeEditor.getBody().setAttribute("contenteditable", false);
    //       //eugene
    //       let table = document.getElementsByTagName("table");
    //       let length = table.length;
    //       for (let i = 0; i <= length - 1; i++) {
    //         let width = table[i].style.width;
    //         if (width.includes("%")) {
    //           let pixelWidth = (parseFloat(width) / 100) * 1200;

    //           let oldAttribute = table[i].getAttribute("style").toString();
    //           let widthIndex = oldAttribute.indexOf("width");

    //           let firstConcat = oldAttribute.substring(0, widthIndex);
    //           let scndConcat = oldAttribute.substring(
    //             widthIndex + 17,
    //             oldAttribute.length
    //           );
    //           table[i].setAttribute(
    //             "style",
    //             `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
    //           );
    //         }
    //       }
    //     }, 100);
    //   }
    //   this.loading = false;
    // });
    // }
  },

  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
  },

  methods: {
    async fetchImage(){
      try{
        console.log(await this.ImageSrc("%E6%8C%AF%E5%88%86%EF%BC%91-20240228120266.JPG"))
      }catch(err){
        if(err){
          console.log(err)
        }
      }
    },
    // loadImage(){
    //   const strToBase64 = (currentItem) =>{
    //     let imageList = []
    //     let findImgLink = new RegExp("http:\\\\\\\\10.11.1.59:2929\\\\uploads\\\\|https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/","g")
    //     let findImgExt = new RegExp(".png|.jpg","gi")
    //     let imgIndexList = []
    //     let images = currentItem.match(findImgLink)
    //     if(images){
    //       let getIndex = ""
    //       while((getIndex = findImgLink.exec(currentItem))){
    //         imgIndexList.push([getIndex.index,getIndex[0]])
    //       }
    //       if(imgIndexList.length>0){
    //         imageList = imgIndexList.map(rec=>{
    //           let endIndex = findImgExt.exec(currentItem,rec[0]).index + 4
    //           return currentItem.substring(rec[0] + rec[1].length, endIndex);
    //         })
    //       }
    //     }
    //     return imageList
    //   }
    //   let s3Keys = this.sekkeiData.htmlContent.map((rec)=>{
    //     return strToBase64(rec)
    //   })
    //   s3Keys = [].concat.apply([], s3Keys);
    //   console.log('images',s3Keys)
    //   let promises = [];
    //   if (s3Keys.length > 0){
    //     for (let i = 0; i < s3Keys.length; i++) {
    //       promises.push(this.ImageSrc(s3Keys[i]));
    //     }
    //     // let newContent = this.sekkeiData.htmlContent.join('@page@')
    //     Promise.all(promises).then(data=>{
    //       console.log('all images',data)
    //     })
    //   }
    // },
    async ImageSrc(imgName) {
      return new Promise((resolve) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
        });

        var options = {
          Bucket: "rulebook.files",
          Key: `Shiyo/${imgName}`,
        };
        // s3.getObject(options, function(err, data) {
        //   if (err) {
        //     console.log(err, err.stack);
        //   }
        //   else {
        //     const base64 = converter.encode(data.Body);
        //     let content = `data:${data.ContentType};base64,`;
        //     let finalcontent = `${content}${base64}`;
        //     this.imagesrc = finalcontent;
        //     resolve(this.imagesrc);
        //   }
        // });
        s3.getObject(options, function(err, data) {
          if (err) console.log(err, err.stack);
          // an error occurred
          else {
            var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
              type: `${data.ContentType};charset=utf-8`,
            });
            resolve(URL.createObjectURL(blob))
          } // successful response
        });
      });
    },
    onCloseShiyoDialog() {
      this.ViewPdfDialog = false;
    },
    print() {
      // window.print();
      // console.log(this.$route)
      window.open(`/SekkeiPrint?id=${this.$route.params.id}`);
    },
    feedback() {
      //feedback link for aws
      // window.open(
      //   `https://documentsearch.hrd-s.com/feedback?id=${this.$route.params.id}`
      // );
      // window.open(
      //   `http://localhost:8081/feedback?id=${this.$route.params.id}&docNo=${this.sekkeiData.docNo}&docType=${this.sekkeiData.documentType}`
      // );
      window.open(
        `https://documentsearch.hrd-s.com/feedback?id=${this.$route.params.id}&docNo=${this.sekkeiData.docNo}&docType=${this.sekkeiData.documentType}`
      );
    },
    Copy() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Copy mail address to clipboard",
        showConfirmButton: false,
        timer: 800,
      });
    },
    SeeMore() {
      if (this.hideProperties) {
        this.hideProperties = false;
      } else {
        this.hideProperties = true;
      }
    },
    CloseTab() {
      // if (this.sekkeiData.deleted == true) {
      //   this.$router.push(`/deletedshiyo`);
      // } else {
      //   window.close();
      // }
      window.close();
    },

    viewHistory(val) {
      console.log((this.sekkeiData.version = val + 1));
      this.sekkeiData.version = val + 1;
      // this.historyDialog = false
    },
    listColorSearch(id) {
      if (this.searchList == id) {
        return "viewed";
      }
    },
    sortHistory() {
      let viewedShiyoDataHolder = this.sekkeiData.data;
      if (this.historyCount == 0) {
        this.historyDetails = viewedShiyoDataHolder;
        this.historyCount = this.historyCount + 1;
      }
    },

    roomPart(item) {
      if (Array.isArray(item)) {
        let string = " ";
        for (let text of item) {
          string += text + "、　";
        }
        // string = string.substring(string.length, 2)
        return string;
      } else {
        return item;
      }
    },

    async getData() {
      let testUrl = window.location.href;
      let url = "";
      let search = "";
      let id = this.$route.params.id;
      let getDocNo = this.$route.query.docNo;
      // let getId;
      // let docNumber = this.$route.query.docNo;

      // console.log(this.$route.query.docNo);
      // if (docNumber) {

      //   getId = new Promise((resolve, reject) => {
      //     axios
      //       .get(urlForDocNO)
      //       .then((res) => {
      //         if (res.data) {
      //           console.log(res.data);
      //           id = res.data.id;
      //           console.log(id);
      //           let idFromDocNo = res.data.id;
      //           resolve(idFromDocNo);
      //         }
      //       })
      //       .catch((err) => {
      //         reject(err);
      //       });
      //   });
      //   await getId
      //     .then((data) => {
      //       id = data;
      //     })
      //     .catch((err) => {
      //       console.error(err);
      //     });
      //   console.log(getId);
      // }
      // this.isDocFavorite = this.$route.params.favorite;
      // console.log(this.isDocFavorite);
      let keywordsArr1;

      if (this.$route.query.search != "" && this.$route.query.search != undefined) {
        search = this.$route.query.search;
        keywordsArr1 = Object.values(this.convertSearchText(search));
        console.log(keywordsArr1);
      }

      this.loading = true;

      if (getDocNo) {
        url = `${this.api}sekkeiGyoumu/getSekkeiGyoumuFinalDataByDocNo/${getDocNo}`;
      } else {
        url = `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuFinalDataTable/${id}`;
      }

      axios.get(url).then(async (res) => {
        document.title = `${res.data.docNo}`;
        this.sekkeiData = res.data;
        // if (
        //   this.sekkeiData.attachmentFileName &&
        //   this.sekkeiData.attachmentFileName.toLowerCase().includes(".pdf")
        // ) {
        //   window.open(
        //     `/sekkei_gyoumu/${this.sekkeiData.id}?docNo=${this.sekkeiData.docNo}&attachmentFileName=${this.sekkeiData.attachmentFileName}&title=${this.sekkeiData.title}`,
        //     `_blank`
        //   );
        //   return window.close();
        // }
        // console.log("line 405 ", res.data);
        let fStr = this.sekkeiData.htmlContent;
        // .split(
        //   `<p><span style="font-size: 17px;"><!-- pagebreak --></span></p>`,
        // )
        if (Array.isArray(keywordsArr1) && keywordsArr1.length > 0) {
          for (let x in keywordsArr1) {
            // var re = new RegExp(keywordsArr1[x], "ig");
            // fStr = fStr.replace(re, function(str) {
            //   return `<span style="background-color:#05e6ff"><b>${str}</b></span>`;
            // });
            //NOTE: modify replacing of highlighted search text 2024-08-20
            if (fStr.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"").includes(keywordsArr1[x])) {
              let textList = fStr.replace(/(<([^>]+)>)|&nbsp;|&nbsp/gi,"@htmlTag").split(/@htmlTag/)
              let tagList = fStr.match(/(<([^>]+)>)|&nbsp;|&nbsp/gi)
              let textWithMark = textList.map((rec,i)=>{
                let regex = new RegExp(`(${keywordsArr1[x]})`,"ig")
                if(textList.length-1 != i){
                  return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`) + tagList[i] 
                }
                else{
                  return rec.replace(regex,`<span style="background-color:#05e6ff">$1</span>`)
                }
              })
              fStr = textWithMark.join('')
            }
          }
          console.log("2");
          this.sekkeiData.htmlContent = fStr;
        }
        this.sekkeiData.htmlContent = this.sekkeiData.htmlContent
          .split("<!-- pagebreak -->")
          .map((rec, i) => {
            return `<div id=p${i + 1}>${rec}</div>`;
          })
          .map((rec) => {
            return rec.replace(
              /http:\\\\10.11.1.59:2929\\uploads\\/g,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            );
          });
        
        // console.log('images',imageDataList)
        // console.log(this.sekkeiData.htmlContent);
        //for pdf files only
        if (res.data.isWithPDFAttachment) {
          this.pdfAlert = true;
          this.fileLoading = true;
          this.fileNamePdf = res.data.attachmentFileName;
          await fileView.getFileSignedUrlViewing(this.fileNamePdf);
          // .then(() => (this.fileLoading = false));
          // .then((res) => {
          //   if (res) {
          //     this.fileLoading = false;
          //   }
          // });
          setTimeout(() => {
            this.fileLoading = false;
          }, 8000);
          //for excel files only
        } else if (res.data.isWithExcelAttachment) {
          console.log("line 412 nag else if", res.data.isWithExcelAttachment);
          this.excelAlert = res.data.isWithExcelAttachment;
          let url = `${process.env.VUE_APP_S3LOCATIONS}/Excel_Files_Sekkei/`;
          // let newFileName = res.data.data[res.data.version - 1].fileName.split('.')[0]
          setTimeout(() => {
            window.open(`${url}${res.data.attachmentFileName}`);
            // window.open(`https://www.google.com`, '_blank')
          }, 300);

          //for folder files
        } else if (res.data.folderAttachment) {
          console.log("line 412 nag else if", res.data.folderAttachment);
          this.folderAlert = true;
          this.folderFileName = res.data.folderAttachment;
          let url = `${process.env.VUE_APP_S3LOCATIONS}/SekkeiGyoumuFolders/`;

          setTimeout(() => {
            window.open(`${url}${res.data.folderAttachment}`);
            // window.open(`https://www.google.com`, '_blank')
          }, 300);
        } else {
          if (testUrl.match(/index/g)) {
            let testSplit = testUrl.split("index");
            let getInd = testSplit[1].split("")[1];
            this.sekkeiData.version = parseInt(getInd) + 1;
          }
          let newTextContent = "";
          if (this.sekkeiData.version == 0) {
            // this.sekkeiData.textContent.map((rec) => {
            //   newTextContent += rec;
            //   console.log("newTextContent", newTextContent);
            // });
            // console.log("lul");
          } else {
            // this.sekkeiData.textContent.map((rec) => {
            //   newTextContent += rec;
            // });
            // console.log("lul");
          }

          let tempTextContent = newTextContent.replace(
            /href="document/g,
            'href="/document'
          ); // for rulebook documents si alde po may gawa nyan
          this.sekkeiData.textContent = tempTextContent.replace(
            /sekkei_document/g,
            "/sekkei_document"
          );
          setTimeout(() => {
            // tinymce.activeEditor
            //   .getBody()
            //   .setAttribute("contenteditable", false);
            //eugene
            let table = document.getElementsByTagName("table");
            let length = table.length;
            for (let i = 0; i <= length - 1; i++) {
              let width = table[i].style.width;
              if (width.includes("%")) {
                let pixelWidth = (parseFloat(width) / 100) * 1200;

                let oldAttribute = table[i].getAttribute("style").toString();
                let widthIndex = oldAttribute.indexOf("width");

                let firstConcat = oldAttribute.substring(0, widthIndex);
                let scndConcat = oldAttribute.substring(
                  widthIndex + 17,
                  oldAttribute.length
                );
                table[i].setAttribute(
                  "style",
                  `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
                );
              }
            }
            // NOTE: for getting image filename 2024-07-25
            // let findStr = new RegExp(`https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/`,"g")
            // let indices = []
            // let imageDataList = []
          
            // for(let i=0; i<this.sekkeiData.htmlContent.length; i++){
            //   let result = ''
            //   let indicePerPage = []
            //   while ((result = findStr.exec(this.sekkeiData.htmlContent[i]))){
            //       indicePerPage.push([result.index,result[0]]);
            //   }
            //   indices.push(indicePerPage)
            // }
            // if(indices.length > 0){
            //   // console.log('indices',indices)
            //   for(let j=0; j<indices.length; j++){
            //     if(indices[j].length>0){
            //       let findExt = new RegExp(".png|.pjpeg|.pjp|.jpg|.jpeg|.jfif|.bmp|.ico|.cur|.tif|.tiff|.webp|.svg|.gif|.avif|.apng", "ig")
            //       let imgArr = []
            //       imgArr = indices[j].map((item) => {
            //         let end = findExt.exec(this.sekkeiData.htmlContent[j],item[0]).index + 4
            //         return {
            //           name:this.sekkeiData.htmlContent[j].substring(item[0] + item[1].length, end)
            //         }
            //       });
            //       imageDataList.push(
            //         {
            //           imgList: imgArr,
            //           pageIndex: j 
            //         }
            //       )
            //     }
            //   }
            //   if(imageDataList.length>0){
            //     for(let k=0; k<imageDataList.length; k++){
            //       let promises = []
            //       for(let m=0; m<imageDataList[k].imgList.length; m++){
            //         let name = imageDataList[k].imgList[m].name
            //         promises.push(this.ImageSrc(name))
            //       }
            //       if(promises.length>0){
            //         Promise.all(promises).then((datas)=>{
            //           // console.log('promises',datas)
            //           if(datas.length>0){
            //             for(let n=0; n<imageDataList[k].imgList.length; n++){
            //               let imgFileName = imageDataList[k].imgList[n].name.replace('(','[(]').replace(')','[)]')
            //               let imgLinks = new RegExp(`https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/${imgFileName}`,"ig")
            //               let pageContent = this.sekkeiData.htmlContent[imageDataList[k].pageIndex].replace(imgLinks,datas[n])
            //               this.sekkeiData.htmlContent[imageDataList[k].pageIndex] = pageContent
            //             }
            //           }
            //           let finalData = [...this.sekkeiData.htmlContent]
            //           this.$set(this.sekkeiData,"htmlContent",finalData)
            //           promises = []
            //         })
            //       }
            //     }
            //   }
            // }


          }, 100);
        }
        this.loading = false;
      });
    },
    closeLoading() {
      this.fileLoading = false;
    },
    getFile() {
      if (this.fileNamePdf) {
        this.loadAgainLoading = true;
        let file = this.fileNamePdf;
        fileView.getFileSignedUrlViewing(file);
        setTimeout(() => {
          this.loadAgainLoading = false;
        }, 3000);
      } else if (this.folderFileName) {
        let url = `${process.env.VUE_APP_S3LOCATIONS}/SekkeiGyoumuFolders/`;
        setTimeout(() => {
          window.open(`${url}${this.folderFileName}`);
        }, 300);
      }
    },
    addFavorite() {
      Swal.fire({
        title: !this.isDocFavorite
          ? "Add this to your favorites?"
          : "Delete this to your Favorite",
        toast: true,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.addToFavorites();
        }
      });
    },
    addToFavorites() {
      console.log(this.userInfo);

      let toInsert = {
        id: this.sekkeiData.id,
        docNo: this.sekkeiData.docNo,
        title: this.sekkeiData.title,
        userId: this.userInfo.id,
        isFavorite: !this.isDocFavorite,
      };

      console.log(toInsert);

      let url = `${this.api}addFavorite/sekkei`;
      let url2 = `${this.api}addFavoriteCount/sekkei`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          if (res.data == "Add Favorite") {
            this.getUserFavorites();
            Swal.fire({
              icon: "success",
              title: `${this.sekkeiData.docNo} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            this.getUserFavorites();
            Swal.fire({
              icon: "error",
              title: `${this.sekkeiData.docNo} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          }
          if (this.searchText != "") {
            // this.onSearch();
            // this.getUserFavorites()
          } else {
            // this.loadSekkeiRecords();
            // this.getUserFavorites()
          }
          // this.$router.push({
          //   path: `/sekkei_document/${
          //     this.sekkeiData.id
          //   }?search=${""}&favorite=${favorite}`,
          // });
        });
      });
    },
    getUserFavorites() {
      this.favoriteList = "";
      this.isDocFavorite = false;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";

      let toGet = {
        userId: this.userInfo.id,
      };
      let url = `https://385rohlp8h.execute-api.us-east-2.amazonaws.com/prod/getFavorites/sekkei`;
      axios.post(url, toGet).then((res) => {
        this.favoriteList = res.data;
        for (let x in this.favoriteList) {
          if (this.favoriteList[x].docNo == this.sekkeiData.docNo) {
            this.isDocFavorite = true;
            this.$forceUpdate();
            return;
          }
        }

        // console.log("line2650", this.favoriteList);
      });
    },
    convertSearchText(word) {
      return {
        ZE_HE: moji(word)
          .convert("ZE", "HE")
          .toString(),
        HE_ZE: moji(word)
          .convert("HE", "ZE")
          .toString(),
        HK_ZK: moji(word)
          .convert("HK", "ZK")
          .toString(),
        ZK_HK: moji(word)
          .convert("ZK", "HK")
          .toString(),
        HG_KK: moji(word)
          .convert("HG", "KK")
          .toString(),
        KK_HG: moji(word)
          .convert("KK", "HG")
          .toString(),
      };
    },
  },
};
</script>

<style scoped>
#hover:hover {
  background-color: #daf5b3;
}

.viewed {
  background-color: #daf5b3;
}
@media only print {
  body {
    position: static;
  }

  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
    overflow-y: visible !important;
    position: relative;
  }
}
</style>
